import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import moment from "moment";

//  Dummy Events
import eventIcon1 from "include/images/dummyEvents/event1.jpg";
import eventIcon2 from "include/images/dummyEvents/event2.jpg";
import eventIcon3 from "include/images/dummyEvents/event3.jpg";
import eventIcon4 from "include/images/dummyEvents/event4.jpg";
import eventIcon5 from "include/images/dummyEvents/event5.jpg";
import eventIcon6 from "include/images/dummyEvents/event6.jpg";
import eventIcon7 from "include/images/dummyEvents/event7.jpg";

//  Components
import EventsCard from "components/eventCards/eventcard";

const EventsSlider = ({ title, events, size }) => {
  const options = {
    // loop: true,
    margin: 0,
    items: events ? (events.length > 2 ? 3 : events.length) : 0,
    dots: false,
    nav: true,
    smartSpeed: 1200,
    autoHeight: false,
    autoplay: true,
    responsive: {
      992: {
        items: events ? (events.length > 2 ? 3 : events.length) : 0,
      },
      768: {
        items: events ? (events.length > 1 ? 2 : events.length) : 0,
      },
      0: {
        items: events ? (events.length > 0 ? 1 : events.length) : 0,
      },
    },
  };
  return (
    <section class="event spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title">
              <h2>{title}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          {events && events.length > 0 && (
            <OwlCarousel className="event__slider owl-carousel" {...options}>
              {events.map((ev) => (
                <div class="col-lg-4">
                  {/* // <div class="col-lg-3 size-6"> */}
                  <EventsCard
                    date={moment(ev.startDate).format("mm DD, YYYY")}
                    image={ev.images[0]}
                    title={ev.title}
                    venue={ev.venue && ev.venue.name}
                    event={ev}
                  />
                </div>
              ))}
            </OwlCarousel>
          )}
        </div>
      </div>
    </section>
  );
};

export default EventsSlider;
