import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import moment from "moment/moment";

const EventsCard = ({ image, title, venue, date, event }) => {
  const navigate = useNavigate();
  return (
    <div class="event__item">
      <div
        class="pointer event__item__pic set-bg"
        style={{
          backgroundImage: `url(${
            process.env.REACT_APP_CDN +
            (event.images && event.images[0] && event.images[0].medium)
          })`,
        }}
        onClick={(e) => {
          navigate(`/event-details/${event._id}`, { state: { event } });
        }}
      >
        <div className="event-category">
          <span>Party</span>
        </div>
      </div>
      <div class="event__item__text">
        <p className="date_card_text">
          {moment(event.startDate).format("ddd, MMM DD, h:mma")} -{" "}
          {moment(event.endDate).format("ddd, MMM DD, h:mma")}
        </p>
        <h4
          className="pointer"
          onClick={(e) => {
            navigate(`/event-details/${event._id}`, {
              state: { event },
            });
          }}
        >
          {title}
        </h4>
        <p>
          <FontAwesomeIcon
            icon={faLocationDot}
            color="#e2093c"
            className="icon-location"
          />{" "}
          {venue}
        </p>
        <a
          href={
            event &&
            event.bookingLinks &&
            event.bookingLinks.length > 0 &&
            event.bookingLinks[0]
          }
          target={"_blank"}
          className="booking-link"
        >
          Buy Ticket{" "}
          <FontAwesomeIcon
            icon={faArrowRightLong}
            className="icon-arrow"
            color="#e2093c"
          />
        </a>
      </div>
    </div>
  );
};

export default EventsCard;
