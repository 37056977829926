import React from "react";

//   Images
import aboutImg from "include/images/about/about.png";
import logo from "include/images/logo.png";
import ap1Img from "include/images/about/ap-1.jpg";
import ap2Img from "include/images/about/ap-2.jpg";
import ap3Img from "include/images/about/ap-3.jpg";
import ap4Img from "include/images/about/ap-4.jpg";
import ap5Img from "include/images/about/ap-5.jpg";
import ap6Img from "include/images/about/ap-6.jpg";
import ap7Img from "include/images/about/ap-7.jpg";
import as1Img from "include/images/services/as-1.jpg";
import as2Img from "include/images/services/as-2.jpg";
import as3Img from "include/images/services/as-3.jpg";
import asIcon1Img from "include/images/services/as-icon-1.png";
import asIcon2Img from "include/images/services/as-icon-2.png";
import asIcon3Img from "include/images/services/as-icon-3.png";
// import as1Img from "include/images/services/as-1.jpg";
import Header from "components/Header/header";
import Footer from "components/Footer/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import EventsSlider from "components/EventsSlider/eventsSlider";
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

//  Dummy Events
import Loader from "../../components/Loader/loader";
import eventIcon5 from "include/images/dummyEvents/event5.jpg";

const About = (props) => {
  return (
    <>
      <Header page="about" />
      <Loader loading={false} />
      <section class="about about--page spad">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="about__pic">
                <img src={logo} alt="" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about__text">
                <div class="section-title">
                  <h2>Party Animals</h2>
                </div>
                <p>
                  Goa's #1 clubbing community bringing you the best and most
                  happening parties across Goa.
                  <br /> At Party Animals we help you choose the right ,unique
                  and spectacular clubbing experience.
                  <br />
                  <br />
                  Don’t stop surprising yourself with all the updates we have
                  for you to experience an incomparable Goa.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="about-pic">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6">
              <div class="row" style={{ height: "100%" }}>
                <div class="col-lg-4 col-md-4 col-sm-4 p-0">
                  <img src={ap1Img} alt="" style={{ height: "50%" }} />
                  <img src={ap2Img} alt="" style={{ height: "50%" }} />
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 p-0">
                  <img src={ap3Img} alt="" style={{ height: "100%" }} />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-8 p-0">
                  <img src={ap4Img} alt="" />
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 p-0">
                  <img src={ap5Img} alt="" style={{ height: "97%" }} />
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 p-0">
                  <img src={ap6Img} alt="" style={{ height: "100%" }} />
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 p-0">
                  <img src={ap7Img} alt="" style={{ marginBottom: 0 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <section class="about-services spad">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-title center-title">
                <h2>What do we host</h2>
                <h1>Best service</h1>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="about__services__item">
                <div
                  class="about__services__item__pic set-bg"
                  style={{ backgroundImage: `url(${as1Img})` }}
                >
                  <div class="icon">
                    <img src={asIcon1Img} alt="" />
                  </div>
                </div>
                <div class="about__services__item__text">
                  <h4>Outdoor</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="about__services__item">
                <div
                  class="about__services__item__pic set-bg"
                  style={{ backgroundImage: `url(${as2Img})` }}
                >
                  <div class="icon">
                    <img src={asIcon2Img} alt="" />
                  </div>
                </div>
                <div class="about__services__item__text">
                  <h4>Clubnight</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="about__services__item">
                <div
                  class="about__services__item__pic set-bg"
                  style={{ backgroundImage: `url(${as3Img})` }}
                >
                  <div class="icon">
                    <img src={asIcon3Img} alt="" />
                  </div>
                </div>
                <div class="about__services__item__text">
                  <h4>Festival</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer page="about" />
    </>
  );
};

export default About;
