import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { eventsReceived } from "store/events";
import { loadEvents } from "store/api/api";
import moment from "moment";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";

//   Images
import emptyIcon from "include/images/empty3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

//  Components
import Header from "components/Header/header";
import Footer from "components/Footer/footer";
import Loader from "../../components/Loader/loader";
import EventsCard from "components/eventCards/eventcard";
import Modal from "components/Modal/modal";

const Events = (props) => {
  const dispatch = useDispatch();
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    category: "",
    startDate: "",
    endDate: "",
    isFeatured: null,
    isRecommended: null,
    isTrending: null,
  });

  const getEvents = async () => {
    setLoadingEvents(true);
    const payLoad = { keyword: search, ...filters };
    if (filters.startDate) {
      payLoad.startDate = moment(filters.startDate).format("x");
    }
    if (filters.endDate) {
      payLoad.endDate = moment(filters.endDate).format("x");
    }
    const params = _.pickBy(payLoad, (value, key) => {
      return !(
        value === undefined ||
        value === "" ||
        value === null ||
        value === {} ||
        value === false
      );
    });
    const res = await loadEvents(params);
    setEvents(res.data);
    setLoadingEvents(false);
  };
  useEffect(() => {
    getEvents();
  }, [search, filters]);
  return (
    <>
      <Header page="events" />{" "}
      <Helmet>
        <title>Party Animals Events</title>
        <meta
          name="description"
          content="Goa's party calendar. Upcoming parties, events, live shows, clubnight in Goa."
        />
      </Helmet>
      <Loader loading={loadingEvents} />
      <section class="discography spad">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-title center-title">
                <h2>All Events</h2>
                <h1>Party</h1>
              </div>
              <div className="title_section">
                <div className="input_container">
                  <input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
                <button
                  type="button"
                  class="btn btn-primary filters_btn"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  <svg
                    aria-hidden="true"
                    role="img"
                    class="MuiBox-root css-0 iconify iconify--ic"
                    sx="[object Object]"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M11 18h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1zm4 6h10c.55 0 1-.45 1-1s-.45-1-1-1H7c-.55 0-1 .45-1 1s.45 1 1 1z"
                    ></path>
                  </svg>
                  Filters
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            {events && events.length > 0 ? (
              events.map((ev) => (
                <div class="col-lg-4">
                  <EventsCard
                    date={moment(ev.startDate).format("mm DD, YYYY")}
                    image={ev.images[0]}
                    title={ev.title}
                    venue={ev.venue && ev.venue.name}
                    event={ev}
                  />
                </div>
              ))
            ) : (
              <div className="empty-data d-flex flex-column align-items-center mt-5">
                <img src={emptyIcon} alt="" />
                <h2>No Events found</h2>
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer page="events" />
      <Modal
        filters={filters}
        setFilters={(val) => setFilters({ ...filters, ...val })}
      />
    </>
  );
};

export default Events;
