import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TextField from "@mui/material/TextField";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
const Modal = ({ filters, setFilters }) => {
  const [value, setValue] = useState();

  return (
    <div
      class="modal right fade"
      id="myModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel2"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel2">
              Filters
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div className="checkboxes_section">
              <h4>Statues</h4>
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="Recommended"
                  checked={filters.isRecommended}
                  onChange={(e) => {
                    setFilters({ isRecommended: !filters.isRecommended });
                  }}
                />
                <label class="form-check-label" for="Recommended">
                  Recommended
                </label>
              </div>
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="Featured"
                  checked={filters.isFeatured}
                  onChange={(e) => {
                    setFilters({ isFeatured: !filters.isFeatured });
                  }}
                />
                <label class="form-check-label" for="Featured">
                  Featured
                </label>
              </div>
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="Trending"
                  checked={filters.isTrending}
                  onChange={(e) => {
                    setFilters({ isTrending: !filters.isTrending });
                  }}
                />
                <label class="form-check-label" for="Trending">
                  Trending
                </label>
              </div>
            </div>
            <div class="form-group select_category">
              <label for="exampleFormControlSelect1">Category</label>
              <select
                class="form-control slec"
                id="category"
                value={filters.category}
                onChange={(e) => {
                  setFilters({ category: e.target.value });
                }}
              >
                <option value=""> </option>
                <option value="clubnight">Clubnight</option>
                <option value="festival">Festival</option>
                <option value="outdoor">Outdoor</option>
                <option value="EDM">EDM</option>
                <option value="commercial">Commercial</option>
                <option value="hip">Hip-hop</option>
                <option value="trance">Trance</option>
                <option value="techno">Techno</option>
                <option value="deep">Deep house</option>
                <option value="psychedelic">Psychedelic</option>
              </select>
            </div>
            {/* <LocalizationProvider dateAdapter={AdapterMoment}>
              <div className="date_time">
                <DateTimePicker
                  label="Event Date"
                  renderInput={(params) => <TextField {...params} />}
                  value={filters.startDate}
                  onChange={(newValue) => {
                    setFilters({ startDate: newValue });
                  }}
                />
              </div>
            </LocalizationProvider> */}
          </div>
          <div className="clear_all">
            <button
              className="btn  btn-danger"
              onClick={(e) => {
                e.preventDefault();
                setFilters({
                  category: "",
                  startDate: "",
                  endDate: "",
                  isFeatured: null,
                  isRecommended: null,
                  isTrending: null,
                });
              }}
            >
              Clear All
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
