import React, { useEffect, useState } from "react";
import logoIcon from "include/images/logo.png";
import { Link } from "react-router-dom";

const Header = ({ loading }) => {
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const loader = document.getElementsByClassName("loader");
    const preloader = document.getElementById("preloder");
    setTimeout(() => {
      if (!loading) {
        loader[0].style.opacity = 0;
        setTimeout(() => {
          preloader.style.opacity = 0;
          preloader.style.display = "none";
        }, 200);
      }
    }, 1000);
  }, [loading]);
  return (
    <div id="preloder">
      <div class="loader"></div>
    </div>
  );
};

export default Header;
