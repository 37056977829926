import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet";

//   Images
import Header from "components/Header/header";
import Footer from "components/Footer/footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faDribbble,
} from "@fortawesome/free-brands-svg-icons";
import { loadBlogs } from "store/api/api";
import Loader from "../../components/Loader/loader";

const Blog = (props) => {
  const { state } = useLocation();
  const { blog } = state;
  return (
    <>
      <Header page="blogs" />
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={blog.description} />
      </Helmet>
      <Loader loading={false} />
      <section class="blog-details spad">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="blog__details__content">
                <div class="blog__details__item">
                  <div
                    class="blog__details__item__pic set-bg"
                    // data-setbg={
                    //   process.env.REACT_APP_CDN +
                    //   (blog.image && blog.image.medium)
                    // }
                    style={{
                      backgroundImage: `url(${
                        process.env.REACT_APP_CDN +
                        (blog.image && blog.image && blog.image.large)
                      })`,
                    }}
                  ></div>
                  <div class="blog__details__item__text">
                    {" "}
                    <div class="blog__details__item__widget">
                      <div class="row">
                        <div class="col-lg-6">
                          <ul>
                            <li>
                              {moment(blog.updatedAt).format("MMM DD, YYYY")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <h3>{blog.title}</h3>
                  </div>
                </div>
                <div class="blog__details__desc">
                  <p>{blog.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer page="blog" />
    </>
  );
};

export default Blog;
