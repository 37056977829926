import React, { useState } from "react";
import Header from "components/Header/header";
import Loader from "components/Loader/loader";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { faCalendar, faLocation } from "@fortawesome/free-solid-svg-icons";

import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";
import { loadEvents } from "store/api/api";

const EventDetail = (props) => {
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = new useParams();
  useEffect(() => {
    setLoading(true);
    loadEvents({ id }).then((res) => {
      setEvent(res.data[0]);
      setLoading(false);
    });
  }, []);
  const { eventSocialProfile, hostSocialMediaLink } = event || {};
  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "Event",
    name: event && event.title,
    description: event && event.description,
    image: [
      process.env.REACT_APP_CDN +
        (event &&
          event.images &&
          event &&
          event.images.length > 0 &&
          event &&
          event.images[0].large),
    ],
    startDate: new Date(event && event.startDate).toISOString(),
    endDate: new Date(event && event.endDate).toISOString(),
    eventAttendanceMode: '"https://schema.org/OfflineEventAttendanceMode"',
    location: {
      "@type": "Place",
      name: event && event.venue && event && event.venue.name,
    },
  };
  return (
    <>
      <Header />{" "}
      <Helmet>
        <title>{event && event.title}</title>
        <meta name="description" content={event && event.description} />
        <meta
          name="keyword"
          content={`${event && event.category}, ${moment(
            event && event.startDate
          ).format("DD/MM/YYYY")}, ${moment(event && event.startDate).format(
            "Do MMM YYYY"
          )} ${event && event.venue && event && event.venue.name}`}
        />
        <script type="application/ld+json">
          {JSON.stringify(articleStructuredData)}
        </script>
      </Helmet>
      <Loader loader={loading} />
      {event && (
        <div className="events-single-container">
          <div
            class="events-hero-bg"
            style={{
              backgroundImage: `url(${
                process.env.REACT_APP_CDN +
                (event.images && event.images[0] && event.images[0].medium)
              })`,
            }}
          ></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="es-content-box">
                  <div className="es-upper d-lg-flex flex-lg-wrap">
                    <div className="esu-left">
                      <div className="featured-image">
                        <img
                          src={
                            process.env.REACT_APP_CDN +
                            (event.images &&
                              event.images[0] &&
                              event.images[0].large)
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="esu-right">
                      <div className="es-content">
                        <div class="esc-text-box">
                          <div class="esc-img">
                            <FontAwesomeIcon icon={faCalendar} />
                          </div>
                          {moment(event.startDate).format("ddd, MMM DD, h:mma")}{" "}
                          - {moment(event.endDate).format("ddd, MMM DD, h:mma")}
                        </div>
                        <h4 className="capitalize">{event.title}</h4>
                        <h6 className="capitalize">{event.category}</h6>
                        <p className="capitalize"></p>
                        {event.hostName && (
                          <div class="speakers-list speakers-list-1 d-flex flex-wrap">
                            <li
                              className=""
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(event.venue.locationLink);
                              }}
                            >
                              Hosted By <strong>{event.hostName}</strong>
                              {event.hostBio && <p>{event.hostBio}</p>}
                            </li>
                          </div>
                        )}
                        {hostSocialMediaLink &&
                          (hostSocialMediaLink.facebook ||
                            hostSocialMediaLink.twitter ||
                            hostSocialMediaLink.instagram ||
                            hostSocialMediaLink.linkedIn) && (
                            <div class="speakers-list speakers-list-1 d-flex flex-wrap">
                              <h4 className="capitalize">
                                Host's Social Media
                              </h4>
                              <ul class="social-link">
                                {hostSocialMediaLink.facebook && (
                                  <li>
                                    <a
                                      href={hostSocialMediaLink.facebook}
                                      target="_blank"
                                      class="facebook"
                                    >
                                      <FontAwesomeIcon
                                        icon={faFacebook}
                                        height={20}
                                      />
                                    </a>
                                  </li>
                                )}
                                {hostSocialMediaLink.twitter && (
                                  <li>
                                    <a
                                      href={hostSocialMediaLink.twitter}
                                      target="_blank"
                                      class="twitter"
                                    >
                                      <FontAwesomeIcon
                                        icon={faTwitter}
                                        height={20}
                                      />
                                    </a>
                                  </li>
                                )}
                                {hostSocialMediaLink.instagram && (
                                  <li>
                                    <a
                                      href={hostSocialMediaLink.instagram}
                                      target="_blank"
                                      class="instagram"
                                    >
                                      <FontAwesomeIcon
                                        icon={faInstagram}
                                        height={20}
                                      />
                                    </a>
                                  </li>
                                )}
                                {hostSocialMediaLink.linkedIn && (
                                  <li>
                                    <a
                                      href={hostSocialMediaLink.linkedIn}
                                      target="_blank"
                                      class="instagram"
                                    >
                                      <FontAwesomeIcon
                                        icon={faLinkedinIn}
                                        height={20}
                                      />
                                    </a>
                                  </li>
                                )}
                              </ul>
                            </div>
                          )}
                        {event.venue && (
                          <div class="speakers-list d-flex flex-wrap">
                            <li
                              className="pointer "
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(event.venue.locationLink);
                              }}
                            >
                              <h4 className="capitalize">Venue</h4>

                              <div class="speakers-box d-flex flex-wrap">
                                <div class="speakers-img-box">
                                  <img
                                    src={
                                      process.env.REACT_APP_CDN +
                                      (event.venue &&
                                        event.venue.image &&
                                        event.venue.image.small)
                                    }
                                    alt=""
                                  />
                                </div>
                                <div class="speakers-text-box">
                                  <strong>
                                    {event.venue && event.venue.name}
                                  </strong>
                                  <p>
                                    {event.venue && event.venue.description}
                                  </p>
                                </div>
                              </div>
                            </li>
                          </div>
                        )}
                        {event.bookingLinks && event.bookingLinks.length > 0 && (
                          <div class="es-btn-box">
                            <a
                              href={event.bookingLinks[0]}
                              class="btn btn-orange btn-lg btn-block"
                              target={"_blank"}
                            >
                              Buy Ticket
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="es-lower d-lg-flex flex-lg-wrap">
                    <div class="esl-left">
                      <p>{event.description}</p>

                      <ul class="social-link">
                        {eventSocialProfile.facebook && (
                          <li>
                            <a
                              href={eventSocialProfile.facebook}
                              target="_blank"
                              class="facebook"
                            >
                              <FontAwesomeIcon icon={faFacebook} height={20} />
                            </a>
                          </li>
                        )}
                        {eventSocialProfile.twitter && (
                          <li>
                            <a
                              href={eventSocialProfile.twitter}
                              target="_blank"
                              class="twitter"
                            >
                              <FontAwesomeIcon icon={faTwitter} height={20} />
                            </a>
                          </li>
                        )}
                        {eventSocialProfile.instagram && (
                          <li>
                            <a
                              href={eventSocialProfile.instagram}
                              target="_blank"
                              class="instagram"
                            >
                              <FontAwesomeIcon icon={faInstagram} height={20} />
                            </a>
                          </li>
                        )}
                        {eventSocialProfile.linkedIn && (
                          <li>
                            <a
                              href={eventSocialProfile.linkedIn}
                              target="_blank"
                              class="instagram"
                            >
                              <FontAwesomeIcon
                                icon={faLinkedinIn}
                                height={20}
                              />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div class="esl-right"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EventDetail;
