import { BrowserRouter, Routes, Route, Redirect } from "react-router-dom";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

//  Routes
import Homepage from "features/home/home";
import About from "features/about/about";
import Blog from "features/blog/blog";
// import Contact from "features/contact/contact";
import Events from "features/events/events";
import Tours from "features/tours/tours";
import EventDetail from "features/eventDetail/eventDetail";
import BlogDetail from "features/blog/blogDetails";

export default function Router(props) {
  // useEffect(() => {
  //   AOS.init();
  //   AOS.refresh();
  // }, []);
  return (
    <BrowserRouter>
      <Helmet>
        <title>Party Animals</title>
        <meta name="description" content="Goa's clubbing community" />
        <meta
          name="keywords"
          content="goa, party, upcoming, festivals, clubs, nightlife, travel, music, events, nightclub,  "
        />
      </Helmet>
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/blogs" element={<Blog />} />
        {/* <Route exact path="/contact-us" element={<Contact />} /> */}
        <Route exact path="/events" element={<Events />} />
        <Route exact path="/tours" element={<Tours />} />
        <Route exact path="/event-details/:id" element={<EventDetail />} />
        <Route exact path="/blog-details" element={<BlogDetail />} />
      </Routes>
    </BrowserRouter>
  );
}
