import React from "react";
import countdownImg from "include/images/countdown-bg.jpg";
import tour1Img from "include/images/tours/tour-1.jpg";
import tour2Img from "include/images/tours/tour-2.jpg";
import tour3Img from "include/images/tours/tour-3.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faDribbble,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhone,
  faFaceSmileBeam,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { faClock, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import Footer from "components/Footer/footer";
import Header from "components/Header/header";

const Tours = () => {
  return (
    <>
      <Header />
      <section
        class="countdown countdown--page spad set-bg"
        style={{ backgroundImage: `url(${countdownImg})` }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="countdown__text">
                <h1>Tomorrowland 2020</h1>
                <h4>Music festival start in</h4>
              </div>
              <div class="countdown__timer" id="countdown-time">
                <div class="countdown__item">
                  <span>20</span>
                  <p>days</p>
                </div>
                <div class="countdown__item">
                  <span>45</span>
                  <p>hours</p>
                </div>
                <div class="countdown__item">
                  <span>18</span>
                  <p>minutes</p>
                </div>
                <div class="countdown__item">
                  <span>09</span>
                  <p>seconds</p>
                </div>
              </div>
              <div class="buy__tickets">
                <a href="#" class="primary-btn">
                  Buy tickets
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="tours spad">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 order-lg-1">
              <div class="tours__item__text">
                <h2>Untold Festival 2020</h2>
                <div class="tours__text__widget">
                  <ul>
                    <li>
                      <i class="fa fa-clock-o"></i>
                      <span>8:00pm</span>
                      <span>Dec 15, 2019</span>
                    </li>
                    <li>
                      <i class="fa fa-map-marker"></i>
                      Funkhaus Berlin, Berlin, Germany
                    </li>
                  </ul>
                  <div class="price">$ 35.00</div>
                </div>
                <div class="tours__text__desc">
                  <p>
                    Held in Europe’s electronic music capital on New Year’s Eve,
                    the seminal Funkhaus Berlin hosts an impressive roster of
                    techno artists worthy.
                  </p>
                  <p>
                    Soundtracking the leap from 2019 into 2020 in what is one of
                    the most anticipated nights of the year, in one of the
                    city’s most hyped venues, HYTE Berlin NYE is the
                  </p>
                </div>
                <a href="#" class="primary-btn border-btn">
                  Buy tickets
                </a>
              </div>
            </div>
            <div class="col-lg-6 order-lg-2">
              <div class="tours__item__pic">
                <img src={tour1Img} alt="" />
              </div>
            </div>
            <div class="col-lg-6 order-lg-3">
              <div class="tours__item__pic tours__item__pic--left">
                <img src={tour2Img} alt="" />
              </div>
            </div>
            <div class="col-lg-6 order-lg-4">
              <div class="tours__item__text tours__item__text--right">
                <h2>Untold Festival 2020</h2>
                <div class="tours__text__widget">
                  <ul>
                    <li>
                      <i class="fa fa-clock-o"></i>
                      <span>8:00pm</span>
                      <span>Dec 15, 2019</span>
                    </li>
                    <li>
                      <i class="fa fa-map-marker"></i>
                      Funkhaus Berlin, Berlin, Germany
                    </li>
                  </ul>
                  <div class="price">$ 35.00</div>
                </div>
                <div class="tours__text__desc">
                  <p>
                    Held in Europe’s electronic music capital on New Year’s Eve,
                    the seminal Funkhaus Berlin hosts an impressive roster of
                    techno artists worthy.
                  </p>
                  <p>
                    Soundtracking the leap from 2019 into 2020 in what is one of
                    the most anticipated nights of the year, in one of the
                    city’s most hyped venues, HYTE Berlin NYE is the
                  </p>
                </div>
                <a href="#" class="primary-btn border-btn">
                  Buy tickets
                </a>
              </div>
            </div>
            <div class="col-lg-6 order-lg-5">
              <div class="tours__item__text">
                <h2>Untold Festival 2020</h2>
                <div class="tours__text__widget">
                  <ul>
                    <li>
                      <i class="fa fa-clock-o"></i>
                      <span>8:00pm</span>
                      <span>Dec 15, 2019</span>
                    </li>
                    <li>
                      <i class="fa fa-map-marker"></i>
                      Funkhaus Berlin, Berlin, Germany
                    </li>
                  </ul>
                  <div class="price">$ 35.00</div>
                </div>
                <div class="tours__text__desc">
                  <p>
                    Held in Europe’s electronic music capital on New Year’s Eve,
                    the seminal Funkhaus Berlin hosts an impressive roster of
                    techno artists worthy.
                  </p>
                  <p>
                    Soundtracking the leap from 2019 into 2020 in what is one of
                    the most anticipated nights of the year, in one of the
                    city’s most hyped venues, HYTE Berlin NYE is the
                  </p>
                </div>
                <a href="#" class="primary-btn border-btn">
                  Buy tickets
                </a>
              </div>
            </div>
            <div class="col-lg-6 order-lg-6">
              <div class="tours__item__pic tours__item__pic--last">
                <img src={tour3Img} alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 order-lg-1">
              <div class="tours__item__text">
                <h4>Timings & Location</h4>
                <div class="tours__text__widget">
                  <ul>
                    <li className="label">Start Date</li>
                    <li>
                      <i>
                        {" "}
                        <FontAwesomeIcon icon={faClock} color="#5c00ce" />
                      </i>
                      <span>8:00pm</span>
                      <span>Dec 15, 2019</span>
                    </li>
                    <li className="label">End Date</li>
                    <li>
                      <i>
                        {" "}
                        <FontAwesomeIcon icon={faClock} color="#5c00ce" />
                      </i>

                      <span>8:00pm</span>
                      <span>Dec 15, 2019</span>
                    </li>
                    <li className="label">Venue</li>
                    <li>
                      <i>
                        <FontAwesomeIcon icon={faLocationDot} color="#5c00ce" />
                      </i>
                      Funkhaus Berlin, Berlin, Germany
                    </li>
                  </ul>
                </div>

                <div class="tours__text__desc">
                  <p>
                    Held in Europe’s electronic music capital on New Year’s Eve,
                    the seminal Funkhaus Berlin hosts an impressive roster of
                    techno artists worthy.
                  </p>
                  <p>
                    Soundtracking the leap from 2019 into 2020 in what is one of
                    the most anticipated nights of the year, in one of the
                    city’s most hyped venues, HYTE Berlin NYE is the
                  </p>
                </div>
                {/* <a href='#' class='primary-btn border-btn'>
									Buy tickets
								</a> */}
              </div>
            </div>
            <div className="col-lg-6 order-lg-2">
              <div class="tours__item__pic">
                <img src={tour1Img} alt="" />
              </div>
            </div>

            <div className="col-lg-6 order-lg-3">
              <div class="tours__item__pic">
                <img src={tour1Img} alt="" />
              </div>
            </div>
            <div className="col-lg-6 order-lg-4">
              <div class="tours__item__text">
                <h4>About the Host</h4>
                <div class="tours__text__widget">
                  <ul>
                    <li className="label">Name</li>
                    <li>
                      <span>Host of the Party</span>
                    </li>
                    <li className="label">Contact</li>
                    <li>
                      <i>
                        <FontAwesomeIcon icon={faPhone} color="#5c00ce" />
                      </i>

                      <a href="tel:Number">
                        <span>Number</span>
                      </a>
                    </li>
                    {/* <li className='label'>Venue</li>
										<li>
											<i>
												<FontAwesomeIcon icon={faLocationDot} color='#5c00ce' />
											</i>
											Funkhaus Berlin, Berlin, Germany
										</li> */}
                  </ul>
                </div>

                <div class="tours__text__desc">
                  <p>
                    Held in Europe’s electronic music capital on New Year’s Eve,
                    the seminal Funkhaus Berlin hosts an impressive roster of
                    techno artists worthy.
                  </p>
                  <p>
                    Soundtracking the leap from 2019 into 2020 in what is one of
                    the most anticipated nights of the year, in one of the
                    city’s most hyped venues, HYTE Berlin NYE is the
                  </p>
                  <div className="social_media">
                    <h4>Social Media</h4>
                    <div class="icon_wrapper">
                      <a href="#">
                        <FontAwesomeIcon icon={faFacebook} size={"2x"} />
                      </a>
                      <a href="#">
                        <FontAwesomeIcon icon={faTwitter} size={"2x"} />
                      </a>
                      <a href="#">
                        <FontAwesomeIcon icon={faInstagram} size={"2x"} />
                      </a>
                      <a href="#">
                        <FontAwesomeIcon icon={faDribbble} size={"2x"} />
                      </a>
                    </div>
                  </div>
                </div>
                {/* <a href='#' class='primary-btn border-btn'>
									Buy tickets
								</a> */}
              </div>
            </div>
          </div>
        </div>
        <div class="container event_location">
          <h4>Event Location</h4>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2942.5524090066037!2d-71.10245469994108!3d42.47980730490846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3748250c43a43%3A0xe1b9879a5e9b6657!2sWinter%20Street%20Public%20Parking%20Lot!5e0!3m2!1sen!2sbd!4v1577299251173!5m2!1sen!2sbd"
            height="585"
            style={{ border: 0 }}
            allowFullScreen=""
          ></iframe>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Tours;
