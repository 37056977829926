import React, { useState } from "react";
import logoIcon from "include/images/logo.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faTwitter,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Header = (props) => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <header class={`header ${props.page !== "home" && "header--normal"}`}>
      <div class="container">
        <div class="row">
          <div class="col-lg-2 col-md-2">
            <div class="header__logo">
              {/* <a href="./index.html"> */}
              <Link to="/">
                <img src={logoIcon} alt="" />
              </Link>
              {/* </a> */}
            </div>
          </div>
          <div class="col-lg-10 col-md-10">
            <div class="header__nav">
              <nav class={`header__menu mobile-menu`}>
                <ul>
                  <li class={props.page === "home" && "active"}>
                    <Link to="/">Home</Link>
                  </li>
                  <li class={props.page === "events" && "active"}>
                    <a href="/events">Events</a>
                  </li>
                  <li class={props.page === "blogs" && "active"}>
                    <a href="/blogs">News</a>
                  </li>
                  <li class={props.page === "about" && "active"}>
                    <Link to="/about-us">About</Link>
                  </li>
                </ul>
              </nav>
              <div class="header__right__social">
                <a
                  href="https://www.facebook.com/arpesh.gadekar"
                  target={"_blank"}
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a
                  href="https://www.twitter.com/thesloppykind"
                  target={"_blank"}
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a
                  href="https://www.instagram.com/__arpesh__"
                  target={"_blank"}
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  href="https://www.youtube.com/@arpeshgadekar"
                  target={"_blank"}
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          class={`containerMenu ${openMenu && "active"}`}
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="70"
            viewBox="0 0 200 200"
          >
            <g strokeWidth="6.5" strokeLinecap="round">
              <path
                d="M72 82.286h28.75"
                fill="#009100"
                fillRule="evenodd"
                stroke="#fff"
              />
              <path
                d="M100.75 103.714l72.482-.143c.043 39.398-32.284 71.434-72.16 71.434-39.878 0-72.204-32.036-72.204-71.554"
                fill="none"
                stroke="#fff"
              />
              <path
                d="M72 125.143h28.75"
                fill="#009100"
                fillRule="evenodd"
                stroke="#fff"
              />
              <path
                d="M100.75 103.714l-71.908-.143c.026-39.638 32.352-71.674 72.23-71.674 39.876 0 72.203 32.036 72.203 71.554"
                fill="none"
                stroke="#fff"
              />
              <path
                d="M100.75 82.286h28.75"
                fill="#009100"
                fillRule="evenodd"
                stroke="#fff"
              />
              <path
                d="M100.75 125.143h28.75"
                fill="#009100"
                fillRule="evenodd"
                stroke="#fff"
              />
            </g>
          </svg>
        </div>
      </div>
      <nav className={`mobile_nav ${!openMenu ? "close_menu" : "mobile_nav"}`}>
        <ul>
          <li
            class={`${props.page === "home" && "active"} ${
              !openMenu ? "close_menu" : "mobile_nav"
            } `}
          >
            <Link to="/">Home</Link>
          </li>
          <li
            class={`${props.page === "events" && "active"} ${
              !openMenu ? "close_menu" : "mobile_nav"
            }`}
          >
            <a href="/events">Events</a>
          </li>
          <li
            class={`${props.page === "blogs" && "active"} ${
              !openMenu ? "close_menu" : "mobile_nav"
            }`}
          >
            <a href="/blogs">News</a>
          </li>
          <li
            class={`${props.page === "about" && "active"} ${
              !openMenu ? "close_menu" : "mobile_nav"
            }`}
          >
            <Link to="/about-us">About</Link>
          </li>
          {/* <li
            class={`${props.page === "contact" && "active"} ${
              !openMenu ? "close_menu" : "mobile_nav"
            }`}
          >
            <a href="/contact-us">Contact</a>
          </li> */}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
