import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// import AOS from "aos";
// import "aos/dist/aos.css";
import Timer from "react-compound-timer";

//   Images
import countdownImg from "include/images/countdown-bg.jpg";
import emptyIcon from "include/images/empty3.png";

import serviceIcon from "include/images/services/service-left.jpg";
import youtubeImg from "include/images/youtube/youtube-1.jpg";
import youtube2Img from "include/images/youtube/youtube-2.jpg";
import youtube3Img from "include/images/youtube/youtube-3.jpg";
import countdownBG from "include/images/countdown-bg.jpg";
import Header from "components/Header/header";
import Banner from "components/Banner/banner";
import Footer from "components/Footer/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import EventsSlider from "components/EventsSlider/eventsSlider";
import EventsSlider2 from "components/EventsSlider/eventsSlider2";
import { useEffect } from "react";
import { loadEvents } from "store/api/api";
import moment from "moment";
import Loader from "components/Loader/loader";
import EventTab from "components/eventTab/eventTab";
import { Helmet } from "react-helmet";

const Homepage = (props) => {
  //  States
  const [featured, setFeatured] = useState([]);
  const [loadingFeatured, setLoadingFeatured] = useState(false);
  const [upcoming, setUpcoming] = useState([]);
  const [loadingUpcoming, setLoadingUpcoming] = useState(false);
  const [trending, setTrending] = useState([]);
  const [loadingTrending, setLoadingTrending] = useState(false);
  const [recommended, setRecommended] = useState([]);
  const [loadingRecommended, setLoadingRecommended] = useState(false);
  const [loadCalendar, setLoadingCalendar] = useState(false);
  const [calendar, setCalendar] = useState([]);
  const [calDate, setCalDate] = useState(
    moment().add(1, "d").startOf("d").format("x")
  );

  const navigate = useNavigate();
  useEffect(() => {
    setLoadingCalendar(true);
    loadEvents({
      eventsOnStartTime: calDate,
      eventsOnEndTime: parseInt(calDate) + 86400000,
    }).then((res) => {
      setCalendar(res.data);
      setLoadingCalendar(false);
    });
  }, calDate);
  // useEffect(() => {
  //   AOS.init();
  //   AOS.refresh();
  // }, []);

  useEffect(() => {
    setLoadingRecommended(true);
    setLoadingUpcoming(true);
    setLoadingTrending(true);
    loadEvents({ startDate: moment().startOf("d").format("x") }).then((res) => {
      setLoadingUpcoming(false);
      setUpcoming(res.data);
    });
    loadEvents({ isRecommended: true }).then((res) => {
      setRecommended(res.data);
      setLoadingRecommended(false);
    });
    loadEvents({ isTrending: true }).then((res) => {
      setTrending(res.data);
      setLoadingTrending(false);
    });
    loadEvents({ isFeatured: true }).then((res) => {
      setFeatured(res.data);
      setLoadingFeatured(false);
    });
  }, []);
  return (
    <>
      <Header />
      <Helmet>
        <title>Party Animals</title>
        <meta
          name="description"
          content="Goa's party calendar. Upcoming parties, events, live shows, clubnight in Goa."
        />
      </Helmet>
      <Loader
        loading={
          loadingFeatured ||
          loadingRecommended ||
          loadingTrending ||
          loadingUpcoming ||
          loadCalendar
        }
      />
      <div className="spad-2"></div>
      <section
        class="countdown countdown--page spad set-bg"
        style={{ backgroundImage: `url(${countdownImg})` }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="countdown__text">
                <h1>Tomorrowland 2020</h1>
                <h4>Music festival start in</h4>
              </div>
              <div class="countdown__timer" id="countdown-time">
                <div class="countdown__item">
                  <span>20</span>
                  <p>days</p>
                </div>
                <div class="countdown__item">
                  <span>45</span>
                  <p>hours</p>
                </div>
                <div class="countdown__item">
                  <span>18</span>
                  <p>minutes</p>
                </div>
                <div class="countdown__item">
                  <span>09</span>
                  <p>seconds</p>
                </div>
              </div>
              <div class="buy__tickets">
                <a href="#" class="primary-btn">
                  Buy tickets
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {recommended && recommended.length > 0 && (
        <EventsSlider
          title="Must Attend Events"
          size={4}
          events={recommended}
        />
      )}
      {upcoming && upcoming.length > 0 && (
        <EventsSlider2 title="Upcoming Events" size={6} events={upcoming} />
      )}
      {trending && trending.length > 0 && (
        <EventsSlider2 title="Trending Events" size={6} events={trending} />
      )}
      <div
        // data-aos="fade-left"
        // data-aos-duration="100"
        className="accordion pad60 fix section-padding30"
      >
        <div class="container">
          <div class="row my-5 responsive-margin">
            <div class="col-lg-11">
              <div class="properties__button mb-40">
                <nav>
                  <div
                    class="nav nav-tabs custom-tabs"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      class="nav-item nav-link active"
                      id="nav-1-tab"
                      data-toggle="tab"
                      href="#nav-1"
                      role="tab"
                      aria-controls="nav-1"
                      aria-selected="true"
                      onClick={async (e) => {
                        e.preventDefault();
                        await setCalDate(
                          moment().add(1, "d").startOf("day").format("x")
                        );
                      }}
                    >
                      {moment().add(1, "d").format("MMM Do")}
                    </a>
                    <a
                      class="nav-item nav-link"
                      id="nav-2-tab"
                      data-toggle="tab"
                      href="#nav-2"
                      role="tab"
                      aria-controls="nav-2"
                      aria-selected="false"
                      onClick={async (e) => {
                        e.preventDefault();
                        await setCalDate(
                          moment().add(2, "d").startOf("day").format("x")
                        );
                      }}
                    >
                      {moment().add(2, "d").format("MMM Do")}
                    </a>
                    <a
                      class="nav-item nav-link"
                      id="nav-3-tab"
                      data-toggle="tab"
                      href="#nav-3"
                      role="tab"
                      aria-controls="nav-3"
                      aria-selected="false"
                      onClick={async (e) => {
                        e.preventDefault();
                        await setCalDate(
                          moment().add(3, "d").startOf("day").format("x")
                        );
                      }}
                    >
                      {moment().add(3, "d").format("MMM Do")}
                    </a>
                    <a
                      class="nav-item nav-link"
                      id="nav-4-tab"
                      data-toggle="tab"
                      href="#nav-4"
                      role="tab"
                      aria-controls="nav-4"
                      aria-selected="false"
                      onClick={async (e) => {
                        e.preventDefault();
                        await setCalDate(
                          moment().add(4, "d").startOf("day").format("x")
                        );
                      }}
                    >
                      {moment().add(4, "d").format("MMM Do")}
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade show active`}
              id={`nav-home`}
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              {calendar && calendar.length > 0 ? (
                <div class="row">
                  <div class="col-lg-11">
                    <div class="accordion-wrapper">
                      <div
                        eventKey="0"
                        className="accordion"
                        id="accordionExample"
                      >
                        <div class="row">
                          {calendar.map((ev) => (
                            <div class="col-lg-3 col-md-6">
                              <div class="videos__item">
                                <div
                                  class="videos__item__pic set-bg pointer"
                                  style={{
                                    backgroundImage: `url(${
                                      process.env.REACT_APP_CDN +
                                      (ev.images &&
                                        ev.images.length > 0 &&
                                        ev.images[0].medium)
                                    })`,
                                  }}
                                  onClick={(e) => {
                                    navigate(`/event-details/${ev._id}`, {
                                      state: { event: ev },
                                    });
                                  }}
                                ></div>
                                <div class="videos__item__text">
                                  <h5
                                    className="pointer"
                                    onClick={(e) => {
                                      navigate(`/event-details/${ev._id}`, {
                                        state: { event: ev },
                                      });
                                    }}
                                  >
                                    {ev.title}
                                  </h5>
                                  <ul>
                                    <li>
                                      {moment(ev.startDate).format("HH:MM A")}
                                    </li>
                                    <li>
                                      {moment(ev.startDate).format(
                                        "MMM DD, YYYY"
                                      )}{" "}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="empty-data d-flex flex-column align-items-center">
                  <img src={emptyIcon} alt="" />
                  <h2>No Events on this day</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer page="home" />
    </>
  );
};

export default Homepage;
