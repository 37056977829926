import React, { useState } from "react";
import footerBG from "include/images/footer-bg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faDribbble,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhone,
  faFaceSmileBeam,
  faPaperPlane,
  faCircleNotch,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { subscribe } from "store/api/api";
// import {} from '@fortawesome/fontawesome-svg-core'
const Footer = (props) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  return (
    <footer
      class={`footer ${props.page !== "home" && "footer--normal"} spad set-bg`}
      style={{ backgroundImage: `url(${footerBG})` }}
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-3 ">
            <div class="footer__address">
              <ul>
                <li>
                  <i>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </i>
                  <p>Email</p>
                  <h6>party@arpesh.in</h6>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 offset-lg-1">
            <div class="footer__social">
              <h2>Party Animals</h2>
              <h6>
                Goa's #1 clubbing community bringing you the best and most
                happening parties across Goa.
                <br />
                At Party Animals we help you choose the right ,unique and
                spectacular clubbing experience.
                <br />
                <br />
                Don’t stop surprising yourself with all the updates we have for
                you to experience an incomparable Goa.
              </h6>
              {/* <div class="footer__social__links">
                <a href="#">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href="#">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="#">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="#">
                  <FontAwesomeIcon icon={faDribbble} />
                </a>
              </div> */}
            </div>
          </div>
          <div class="col-lg-3 offset-lg-1 col-md-6">
            <div class="footer__newslatter">
              <h4>Subscribe to our Newsletter</h4>
              <form
                action="#"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!email) {
                    return setMessage({
                      message: "Please enter a valid Email Address",
                      type: "error",
                    });
                  }
                  setMessage(null);

                  setLoading(true);
                  subscribe({ email: email }).then((res) => {
                    setMessage({
                      message: "You have subscribed to our newsletter",
                      type: "success",
                    });
                    setLoading(false);
                  });
                }}
              >
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  type="submit"
                  disabled={loading || (message && message.type === "success")}
                >
                  <i>
                    <FontAwesomeIcon
                      className={loading ? "fa-spin" : ""}
                      icon={
                        loading
                          ? faCircleNotch
                          : message && message.type === "success"
                          ? faCircleCheck
                          : faPaperPlane
                      }
                    />
                  </i>
                </button>
              </form>
              {message && <p className={message.type}>{message.message}</p>}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
