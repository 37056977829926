import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import moment from "moment";

//  Dummy Events
import eventIcon1 from "include/images/dummyEvents/event1.jpg";

//  Components
import EventsCard from "components/eventCards/eventcard";

const EventsSlider = ({ title, events }) => {
  const options = {
    // loop: true,
    margin: 0,
    items: events.length > 4 ? 5 : events.length,
    dots: false,
    nav: true,
    smartSpeed: 1200,
    autoHeight: false,
    autoplay: true,
    margin: 10,
    responsive: {
      992: {
        items: events.length > 4 ? 5 : events.length,
      },
      768: {
        items: events.length > 3 ? 4 : events.length,
      },
      400: {
        items: 2,
      },
      0: {
        items: 1,
      },
    },
  };
  const winWidth = window.outerWidth;
  return (
    <section class="event ">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title">
              <h2>{title}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          {(winWidth >= 992 && events.length > 4) ||
          (winWidth < 992 && events.length > 1) ||
          (winWidth < 768 && events.length > 1) ? (
            <OwlCarousel
              className="event__slider size-6 owl-carousel"
              {...options}
            >
              {events.map((ev) => (
                <EventsCard
                  date={moment(ev.startDate).format("mm DD, YYYY")}
                  image={ev.images[0]}
                  title={ev.title}
                  venue={ev.venue && ev.venue.name}
                  event={ev}
                />
              ))}
            </OwlCarousel>
          ) : (
            events.map((ev) => (
              <div className="col-lg-3 size-6">
                <EventsCard
                  date={moment(ev.startDate).format("mm DD, YYYY")}
                  image={ev.image}
                  title={ev.title}
                  venue={ev.venue && ev.venue.name}
                  event={ev}
                />
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default EventsSlider;
