import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import blog1Img from "include/images/blog/blog-1.jpg";
import moment from "moment";

const BlogCard = ({ image, title, venue, date, blog }) => {
  const navigate = useNavigate();
  return (
    <div class="blog__item">
      <div
        class="blog__item__pic pointer"
        onClick={(e) => {
          navigate("/blog-details", { state: { blog } });
        }}
      >
        <img
          src={
            image ? `${process.env.REACT_APP_CDN}${image.small}` : `${blog1Img}`
          }
          alt=""
        />
      </div>
      <div class="blog__item__text">
        <h5
          className="pointer"
          onClick={(e) => {
            navigate("/blog-details", { state: { blog } });
          }}
        >
          {title}
        </h5>
        <ul>
          <li>{moment(date).format("MMM DD, YYYY")}</li>
        </ul>
      </div>
    </div>
  );
};

export default BlogCard;
