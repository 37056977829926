import axios from "axios";
import moment from "moment";

export const loadEvents = async (params) => {
  params.startDate = moment().format("x");
  params.sort = "startedAt";
  params.order = "asc";
  try {
    const response = await axios({
      method: "get",
      params,
      url: `${process.env.REACT_APP_API}events/public`,
    });
    return response.data;
  } catch (e) {
    throw null;
  }
};
export const loadBlogs = async (params) => {
  try {
    const response = await axios({
      method: "get",
      params,
      url: `${process.env.REACT_APP_API}blogs`,
    });
    return response.data;
  } catch (e) {
    throw null;
  }
};
export const subscribe = async (data) => {
  console.log("data:", data);
  try {
    const response = await axios({
      method: "post",
      data,
      url: `${process.env.REACT_APP_API}subscribe`,
    });
    return response.data;
  } catch (e) {
    throw null;
  }
};
