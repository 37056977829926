import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

//   Images
import Header from "components/Header/header";
import Footer from "components/Footer/footer";

//  Components
import BlogCard from "./blogCard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faDribbble,
} from "@fortawesome/free-brands-svg-icons";
import emptyIcon from "include/images/empty3.png";
import { loadBlogs } from "store/api/api";
import Loader from "../../components/Loader/loader";
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

const Blog = (props) => {
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setLoading(true);
    loadBlogs({}).then((res) => {
      setBlogs(res.data);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Header page="blogs" />{" "}
      <Helmet>
        <title>Party Animals News</title>
        <meta
          name="description"
          content="Goa's party news. Upcoming parties news, news, live shows news, clubnight in Goa news."
        />
      </Helmet>
      <Loader loading={loading} />
      <section class="blog spad">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-title">
                <h2>Latest News</h2>
                <h1>Our Blogs</h1>
              </div>
              <div class="row">
                {!loading &&
                  (blogs && blogs.length > 0 ? (
                    blogs.map((blog) => (
                      <div class="col-lg-3 col-md-6 col-sm-6">
                        <BlogCard
                          image={blog.image}
                          title={blog.title}
                          description={blog.description}
                          date={blog.updatedAt}
                          blog={blog}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="empty-data d-flex flex-column align-items-center mt-5">
                      <img src={emptyIcon} alt="" />
                      <h2>No News found</h2>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer page="blog" />
    </>
  );
};

export default Blog;
